import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { resetPassword, useForgotPassword } from "../core/_requests";
import { useIntl } from "react-intl";
import { removeAllSpaces } from "../../../../_metronic/helpers";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { ShowError } from "../../errors/ShowError";

const initialValues = {
  nickname: "",
  password: "",
  changepassword: "",
  showPassword: false,
  showConfirmPassword: false,
  totp: "",
};

const regexTotp = /^[0-9]+$/;
const regexPass =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-={}[\]:;"'<>,.?]{8,}$/;

export function ForgotPassword() {
  const intl = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const [error, setError] = useState<any | null>(null);

  const {
    mutate: mutateForgotPassword,
    isLoading: isForgotPasswordLoading,
    error: errorForgotPassword,
  } = useForgotPassword();

  const forgotPasswordSchema = Yup.object().shape({
    nickname: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .min(
        8,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 8 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(
        regexPass,
        "Пароль должен содержать минимум 8 символов, состоять из букв и цифр и может включать спецсимволы"
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),

    changepassword: Yup.string()
      .min(
        8,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 8 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(
        regexPass,
        "Пароль должен содержать минимум 8 символов, состоять из букв и цифр и может включать спецсимволы"
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" })
      ),
    totp: Yup.string()
      .min(
        6,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 6 })
      )
      .max(
        6,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 6 })
      )
      .matches(regexTotp, "Пароль не должен содержать спецсимволы")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      setHasErrors(undefined);

      try {
        mutateForgotPassword(
          {
            new_password: values.password,
            nickname: values.nickname,
            totp_code: values.totp,
          },
          {
            onSuccess: () => {
              // handlerResetPassIsSuccess();

              setHasErrors(false);
              setLoading(false);
              setTimeout(() => {
                navigate("/auth/login");
              }, 2500);
            },
          }
        );
      } catch (error: any) {
        console.error(error);
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus(
          intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" })
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  // useEffect(() => {
  //   if (formik?.values?.email) {
  //     formik.values.email = removeAllSpaces(formik?.values?.email);
  //   }
  // }, [formik?.values?.email]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    /* do something like submit a form and then refresh recaptcha */
  }, []);

  useEffect(() => {
    if (errorForgotPassword) {
      if (
        errorForgotPassword?.response?.data?.detail === "Неправильный totp код"
      ) {
        setError(
          "2FA ключ указан неверно, повторите попытку. Если у Вас не подключен Google аутентификатор, обратитесь в поддержку."
        );
        formik.values.totp = "";
        formik.touched.totp = false;
      } else {
        setError(errorForgotPassword);
      }
    }
  }, [errorForgotPassword]);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-8">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-6">
          {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}?
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Заполните все поля, чтобы сбросить пароль, или обратитесь в поддержку.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Заполните все поля верно, что бы сменить пароль
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className="mb-8 bg-light-info p-8 rounded">
          <div className="text-info">
            Новый пароль установлен. Войдите в систему
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-gray-900 fs-6 required">
          Имя пользователя
        </label>
        <input
          type="text"
          placeholder="user.example"
          autoComplete="off"
          {...formik.getFieldProps("nickname")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.nickname && formik.errors.nickname },
            {
              "is-valid": formik.touched.nickname && !formik.errors.nickname,
            }
          )}
        />
        {formik.touched.nickname && formik.errors.nickname && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.nickname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="fv-row mb-5" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6 required">
            Новый пароль
          </label>
          <div className="position-relative mb-3">
            <input
              type={formik.values.showPassword ? "text" : "password"}
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
              })}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
              onClick={() =>
                formik.setFieldValue(
                  "showPassword",
                  !formik.values.showPassword
                )
              }
            >
              <i
                className={`bi fs-2 ${
                  formik.values.showPassword
                    ? "bi-eye-slash-fill"
                    : "bi-eye-fill"
                }`}
              ></i>
            </span>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          {intl.formatMessage(
            { id: "AUTH.VALIDATION.MIN_LENGTH_TITLE" },
            { length: 8 }
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6 required">
          {intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
        </label>
        <div className="position-relative">
          <input
            type={formik.values.showConfirmPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.CONFIRM_PASSWORD",
            })}
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword &&
                  !formik.errors.changepassword,
              }
            )}
          />
          <span
            className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
            onClick={() =>
              formik.setFieldValue(
                "showConfirmPassword",
                !formik.values.showConfirmPassword
              )
            }
          >
            <i
              className={`bi fs-2 ${
                formik.values.showConfirmPassword
                  ? "bi-eye-slash-fill"
                  : "bi-eye-fill"
              }`}
            ></i>
          </span>
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fw-bolder text-gray-900 fs-6 required">
          2FA ключ
        </label>
        <input
          type="text"
          placeholder="XXX XXX"
          autoComplete="off"
          {...formik.getFieldProps("totp")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.totp && formik.errors.totp },
            {
              "is-valid": formik.touched.totp && !formik.errors.totp,
            }
          )}
        />
        {formik.touched.totp && formik.errors.totp && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.totp}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {error && <div className="my-10">{ShowError(error)}</div>}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-between pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={loading}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_CONFIRM" })}
          </span>
          {loading && (
            <span className="indicator-progress">
              {intl.formatMessage({ id: "GLOBAL.WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link reloadDocument to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
