import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "../../../../app/pages/main-page/components/NavLink";
// import SVG from "react-inlinesvg";
import { KTSVG } from "../../../../_metronic/helpers";
import { Tooltip } from "bootstrap";

// Да, еще один вариант - использовать require для импорта файла из папки public:

// js

// Copy code
import confidendalPolicyPdf from "../../../../../src/_metronic/assets/files/confidendal-policy.pdf";
import rulesInfoPdf from "../../../../../src/_metronic/assets/files/rulesInfo.pdf";
import { useAuth } from "../../../../app/modules/auth";

// const HOST = process.env.REACT_APP_AGENT_SITE_HOST;
/* eslint-disable react/jsx-no-target-blank */
const Footer = () => {
  const intl = useIntl();

  const [tooltip, setTooltip] = useState<any>();
  const ref = useRef<HTMLButtonElement | null>(null);

  // const { roleUser } = useAuth();

  useEffect(() => {
    if (ref.current) {
      const tooltip = new Tooltip(ref.current, {
        trigger: "manual",
        title: "Скопированно",
        offset: [0, 5],
      });
      setTooltip(tooltip);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      const tooltip = new Tooltip(ref.current, {
        trigger: "manual",
        title: "Скопированно",
        offset: [0, 5],
      });
      setTooltip(tooltip);
    }
  }, []);

  const copyToClipBoard = async (address: string | undefined) => {
    try {
      await navigator.clipboard.writeText(address ?? "");
      if (tooltip) {
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
        }, 800);
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const emailCashback = "help@cashback.gg";

  return (
    <div className="container app-container container-xxl fw-bold pt-5 pb-5">
      <div className="row  position-relative">
        {/* align-items-baseline */}

        <div className="col d-flex flex-column gap-4">
          <NavLink
            title={
              <span className="fw-bold fs-6 ">
                {intl.formatMessage({ id: "FOOTER.SUPPORT" })}
              </span>
            }
            to="#"
          />
          <div className="fs-6 ml-4 d-flex  align-items-center">
            {emailCashback}
          </div>
        </div>

        <div className="col  d-flex flex-column text-end">
          <NavLink
            title={
              <span
                className="text-white text-uppercase fw-bolder fst-italic fs-2x me-15"
                style={{ fontFamily: "Open Sans" }}
              >
                cashback
              </span>
            }
            to="/"
          />
          <div
            className="position-absolute bottom-0 end-0 bg-light rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: "40px", height: "40px" }}
          >
            18+
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
