import { useIntl } from "react-intl";
import { NavLink } from "../../../../../app/pages/main-page/components/NavLink";
import { CashbackLogo } from "../../../../../app/pages/main-page/components/CashbackLogo";
import { useLocation } from "react-router-dom";

export function MenuInner() {
  return (
    <>
      <div className="p-10 p-lg-0 container text-center align-items-center align-self-start align-self-lg-center">
        <div className="row align-items-center flex-column flex-lg-row fs-4 fs-lg-6">
          <div className="col col-lg-3 text-start "></div>
        </div>
      </div>
    </>
  );
}
